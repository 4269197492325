import { useBreakpoints } from '@/shared/providers/Device';
import { FeaturedArticleStoryblok } from '@/shared/types/storyblok/storyblok-types';
import FeaturedArticleDesktop from './_components/Desktop/FeaturedArticleDesktop';
import FeaturedArticleMobile from './_components/Mobile/FeaturedArticleMobile';

const FeaturedArticle: React.FC<FeaturedArticleStoryblok> = (props: FeaturedArticleStoryblok) => {
    const { isExtraLarge, isLarge, isMedium } = useBreakpoints();
    return isExtraLarge || isLarge || isMedium ? (
        <FeaturedArticleDesktop {...props} />
    ) : (
        <FeaturedArticleMobile {...props} />
    );
};
export default FeaturedArticle;

import { SlugType } from '@/components/Metatags/Metatags';
import router from 'next/router';

import { ButtonStoryblok } from '../types/storyblok/storyblok-types';

export function getSafeUrl(url: string) {
    if (url === '' || url === undefined) {
        return window.location.href;
    }
    let newUrl: string = '';
    if (url.substring(0, 4) === 'www.') {
        url = newUrl.concat('https://', url);
    }
    try {
        return new URL(url).toString();
    } catch (err) {
        return window.location.href;
    }
}
export function navigate(data: ButtonStoryblok): string {
    let url: string;
    switch (data.blok?.buttonLink?.linktype) {
        case 'story':
            url = data.blok.buttonLink.cached_url === 'home' ? '/' : data.blok.buttonLink.cached_url || '';
            break;
        default:
            url = getSafeUrl(data.blok.buttonLink.url);
            break;
    }
    return url;
}

export const pushSlug = (slug: string, lang?: string) => {
    const isHomeSlug = (slug: string) => /(?:^|\/)home(?:\/|$)/.test(slug);
    let realSlug = isHomeSlug(slug) ? '/' : slug;
    lang ? router.push(realSlug, '', { locale: lang }).then(() => window.location.reload()) : router.push(realSlug);
};

export const navigateBack = (bool?: boolean) => {
    let backLink = '/';
    // let internal = bool ?? false;
    // const { slug } = router.query;
    // if (slug?.[0] && slug?.[0] !== 'blog') {
    //     backLink = slug?.[0];
    // }

    return bool ? router.back() : router.push(backLink);
};

export const closeArticle = (slugs: any) => {
    const lang = router.locale;
    let backLink;
    if (slugs?.default === 'home') backLink = '/';
    else if (lang === 'en') {
        backLink = `/${slugs?.default ?? ''}`;
    } else {
        backLink = `/${slugs?.translatedSlugs?.find((slug: SlugType) => slug.lang === lang)?.path ?? ''}`;
    }
    return router.push(backLink);
};

import Container from '@/components/Container/Container';
import { Typography } from '@/shared';
import { useThemeSection } from '@/shared/providers/SectionContext';
import {
    AccordionStoryblok,
    ContentItemStoryblok,
    FeaturedArticleStoryblok,
    UniversalTextBlockStoryblok,
} from '@/shared/types/storyblok/storyblok-types';
import { Icon } from '@/shared/ui/Icon/Icon';
import clsx from 'clsx';
import { Fragment, useRef, useState } from 'react';
import ContentItem from '../../../ContentItem/ContentItem';
import styles from './Accordion.module.scss';

function contentItem(
    textBlock: UniversalTextBlockStoryblok[],
    title: string,
    uid: string,
    featuredArticles?: FeaturedArticleStoryblok[],
): ContentItemStoryblok {
    return {
        blok: {
            itemTitle: title,
            _uid: uid,
            component: 'contentItem',
            universalTextBlock: { blok: textBlock },
            featuredArticles: { blok: featuredArticles },
        },
    } as any as ContentItemStoryblok;
}
const Accordion: React.FC<AccordionStoryblok> = ({ blok }) => {
    const darkTheme = useThemeSection() === 'Dark' ?? false;
    const isNumbered = blok.Numbered,
        accordionItems = blok.accordeonItems;
    const [openIds, setIsOpen] = useState(['']);
    const elHref = useRef<HTMLDivElement | null>(null);
    let count = 0;

    return (
        <Container code="fullpage" className={{ wrap: styles.container }} blok={blok}>
            {accordionItems?.length > 0 && (
                <div
                    style={{ borderBottomColor: darkTheme ? 'var(--core20)' : 'var(--core80)' }}
                    className={clsx(darkTheme && styles.isDark, styles.border)}
                    id={blok?.blok?.anchor}
                >
                    {accordionItems.map((item: ContentItemStoryblok) => {
                        const isOpen = openIds.includes(item._uid);
                        return (
                            <Fragment key={item._uid}>
                                <div
                                    className={clsx(styles.row_container, isOpen && styles.open)}
                                    onClick={() => {
                                        isOpen
                                            ? setIsOpen(openIds.filter(a => a !== item._uid))
                                            : setIsOpen([...openIds, item._uid]);
                                    }}
                                >
                                    <Container code="standard" className={{ wrap: clsx(styles.row) }}>
                                        <div className={isNumbered ? styles.number : styles.hidden}>{++count}</div>
                                        <div style={{ width: '100%' }}>
                                            <Typography.H2 darkTheme={darkTheme} className={styles.h3}>
                                                {item.itemTitle}
                                            </Typography.H2>
                                        </div>
                                        <Icon
                                            name={isOpen ? 'less' : 'plus'}
                                            className={clsx(isOpen ? styles.animateIconOpen : styles.animateIconClose)}
                                        />
                                    </Container>
                                </div>
                                <Container
                                    code="fullpage"
                                    className={{
                                        wrap: clsx(styles.desc, { [styles.active]: isOpen }),
                                    }}
                                >
                                    <div className={styles.contentWrapper}>
                                        <div className={styles.innerText} ref={elHref}>
                                            <ContentItem
                                                {...contentItem(
                                                    item.universalTextBlock,
                                                    item.itemTitle,
                                                    item._uid,
                                                    item.featuredArticles,
                                                )}
                                            />
                                        </div>
                                    </div>
                                </Container>
                            </Fragment>
                        );
                    })}
                </div>
            )}
        </Container>
    );
};
export default Accordion;

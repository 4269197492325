import Container from '@/components/Container/Container';
import ContentItem from '@/components/ContentItem/ContentItem';
import { Typography } from '@/shared';
import { useThemeSection } from '@/shared/providers/SectionContext';
import {
    AccordionStoryblok,
    ContentItemStoryblok,
    FeaturedArticleStoryblok,
    UniversalTextBlockStoryblok,
} from '@/shared/types/storyblok/storyblok-types';
import { Icon } from '@/shared/ui/Icon/Icon';
import clsx from 'clsx';
import styles from './ArticleAccordion.module.scss';
import { useState } from 'react';

function contentItem(
    textBlock: UniversalTextBlockStoryblok[],
    title: string,
    uid: string,
    featuredArticles: FeaturedArticleStoryblok[] | undefined,
): ContentItemStoryblok {
    return {
        blok: {
            _uid: uid,
            itemTitle: title,
            component: 'contentItem',
            universalTextBlock: { blok: textBlock },
            featuredArticles: { blok: featuredArticles },
        },
    } as any as ContentItemStoryblok;
}

const ArticleAccordion: React.FC<AccordionStoryblok> = ({ blok }) => {
    const darkTheme = useThemeSection() === 'Dark' ?? false;
    const isNumbered = blok.Numbered;
    const accordionItems = blok.accordeonItems;
    const [openIds, setIsOpen] = useState(['']);
    const [wasClosedIds, setWasClosed] = useState(['']);
    let count = 0;

    return (
        <Container blok={blok} code="large" className={{ wrap: clsx(styles.container, darkTheme && styles.dark) }}>
            {accordionItems?.length > 0 && (
                <div className={styles.bottom} id={blok?.blok?.anchor}>
                    {accordionItems.map((item: ContentItemStoryblok) => {
                        const isOpen = openIds.includes(item._uid);
                        const isClosed = wasClosedIds.includes(item._uid);
                        return (
                            <div
                                key={item._uid}
                                className={clsx(styles.row_container, isOpen && styles.open)}
                                onClick={() => {
                                    if (isOpen) {
                                        setIsOpen(openIds.filter(a => a !== item._uid));
                                        setWasClosed([...wasClosedIds, item._uid]);
                                    } else {
                                        setIsOpen([...openIds, item._uid]);
                                        if (isClosed) {
                                            setWasClosed(openIds.filter(a => a !== item._uid));
                                        }
                                    }
                                }}
                            >
                                <Container code="standard" className={{ wrap: styles.row }}>
                                    <Typography.H4
                                        className={clsx(
                                            isNumbered ? styles.number : styles.hidden,
                                            isOpen && styles.openText,
                                        )}
                                    >
                                        {++count}
                                    </Typography.H4>
                                    <div style={{ width: '100%' }}>
                                        <Typography.H4
                                            darkTheme={darkTheme}
                                            className={clsx(styles.title, isOpen && styles.openText)}
                                        >
                                            {item.itemTitle}
                                        </Typography.H4>
                                    </div>
                                    <Icon
                                        name={isOpen ? 'less' : 'plus'}
                                        className={clsx(
                                            isOpen && styles.animateIconOpen,
                                            isClosed && styles.animateIconClose,
                                        )}
                                    />
                                </Container>

                                <div className={styles.desc} style={{ height: isOpen ? 'max-content' : 0 }}>
                                    <ContentItem
                                        {...contentItem(
                                            item.universalTextBlock,
                                            item.itemTitle,
                                            item._uid,
                                            item?.featuredArticles,
                                        )}
                                    />
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </Container>
    );
};

export default ArticleAccordion;

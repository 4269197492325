import { ContentItemStoryblok, FeaturedArticleStoryblok } from '@/shared/types/storyblok/storyblok-types';
import FeaturedArticle from '../FeaturedArticle/FeaturedArticle';
import UniversalTextBlock from '../UniversalTextBlock/UniversalTextBlock';
import styles from './ContentItem.module.scss';
import Container from '../Container/Container';

const ContentItem = (data: ContentItemStoryblok) => {
    const uniText = {
        blok: data.blok?.universalTextBlock?.blok
            ? data.blok.universalTextBlock.blok[0]
            : data.blok?.universalTextBlock[0],
    };
    const hasArticles = data.blok?.featuredArticles?.blok?.length > 0;
    const articles = hasArticles ? { blok: data.blok.featuredArticles.blok[0] } : {};
    return (
        <Container code="fullpage">
            <div className={styles.uniText_container}>
                <UniversalTextBlock {...(uniText as any)} />
            </div>
            <div className={hasArticles ? styles.slider : styles.hidden}>
                {hasArticles && <FeaturedArticle {...(articles as any as FeaturedArticleStoryblok)} />}
            </div>
        </Container>
    );
};

export default ContentItem;

export type SortByType = 'a_z' | 'z_a' | 'date_created' | 'date_updated' | 'release_date';
export const getSortedContent = (content: any[], sortBy: SortByType, noTitleSorting = false) => {
    let filteredContent = content.filter(element => !element?.is_startpage);

    switch (sortBy) {
        case 'a_z':
            if (!noTitleSorting) {
                return filteredContent.sort((a, b) => a?.content?.title?.localeCompare(b?.content?.title));
            }
        case 'z_a':
            if (!noTitleSorting) {
                return filteredContent.sort((a, b) => b?.content?.title?.localeCompare(a?.content?.title));
            }
        case 'date_created':
            return filteredContent.sort(
                (a, b) => new Date(b?.created_at).getTime() - new Date(a?.created_at).getTime(),
            );
        case 'date_updated':
            return filteredContent.sort(
                (a, b) => new Date(b?.first_published_at).getTime() - new Date(a?.first_published_at).getTime(),
            );
        case 'release_date':
            return filteredContent.sort((a, b) => {
                const dateA = a?.content.release ? new Date(a.content.release) : new Date(0);
                const dateB = b?.content.release ? new Date(b.content.release) : new Date(0);

                return dateB.getTime() - dateA.getTime();
            });
        default:
            return filteredContent;
    }
};

export const removeLangPrefix = (path: string) => {
    if (!path) return;
    const regex = /^(\/?[a-z]{2})\/(.*)$/;

    const match = path.match(regex);
    if (match) {
        return '/' + match[2];
    }

    return '/' + path;
};
